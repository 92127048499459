import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import Form from "./form";
import useABM from "./useABM";

const TypesOfSkillsTransversalIndicators = ({ parameter, handleGoBack }) => {

  const {
    isLoading,
    data,
    dateFormat,
    state,
    handlers,
  } = useABM(parameter.code);

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />

  const addItem = () =>
    <CustomIconButton
      title={"Crear"}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />

  const editItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />

  return (
    <>
      {
        state.createEditFormIsOpen ?
          <Form
            isLoading={isLoading}
            data={state.selectedItem}
            parameter={parameter}
            handleClose={handlers.handleCloseEditDialog}
          />
          :
          <CommonPage
            isLoading={isLoading}
            columnDefPage={'typesOfSkillsTransversalIndicators'}
            title={`Indicadores para la competencia transversal: ${parameter.name}`}
            gridTitle={`Indicadores para la competencia transversal: ${parameter.name}`}
            menuItems={[goBackItem, addItem, editItem, deleteItem]}
            rowData={data}
            dateFormat={dateFormat}
            hasExpand
            hasHelp
          />
      }
    </>
  );
}

export default TypesOfSkillsTransversalIndicators;