import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import DuplicateSkillDialog from "./dialogs/duplicateSkillDialog";
import ViewDialog from "./dialogs/viewDialog";
import Form from "./form";
import useABM from "./useABM";

const TypesOfSkillsTransversal = ({ handleOpenParameterPage, handleGoBack }) => {

  const {
    isLoading,
    data,
    dateFormat,
    state,
    handlers,
  } = useABM();


  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />

  const addItem = () =>
    <CustomIconButton
      title={"Crear"}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />

  const editItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />

  const viewItem = (gridRef) =>
    <CustomIconButton
      title={"Ver indicadores"}
      onClick={() => handlers.handleOpenViewDialog(gridRef)}
      type={"preview"}
    />

  const duplicateItem = (gridRef) => Boolean(data?.length) &&
    <CustomIconButton
      type={'duplicate'}
      title={'Duplicar competencia'}
      onClick={() => handlers.handleOpenDuplicateSkillDialog(gridRef)}
    />

  const handleRowClick = (row) => {
    handleOpenParameterPage(row.data);
  }

  return (
    <>
      {
        state.createEditFormIsOpen ?
          <Form
            isLoading={isLoading}
            data={state.selectedItem}
            handleClose={handlers.handleCloseEditDialog}
          />
          :
          <CommonPage
            isLoading={isLoading}
            columnDefPage={paths.typesOfSkillsTransversal}
            title={"Competencias transversales"}
            gridTitle={"Competencias transversales"}
            menuItems={[goBackItem, addItem, editItem, deleteItem, duplicateItem, viewItem]}
            rowData={data}
            dateFormat={dateFormat}
            handleRowClick={handleRowClick}
            hasExpand
            hasHelp
          >
            {
              state.duplicateSkillDialogIsOpen &&
              <DuplicateSkillDialog
                open={state.duplicateSkillDialogIsOpen}
                data={state.selectedItem}
                isLoading={isLoading}
                handleConfirm={handlers.handleDuplicateSkill}
                handleClose={handlers.handleCloseDuplicateSkillDialog}
              />
            }
            {
              state.viewDialogIsOpen &&
              <ViewDialog
                open={state.viewDialogIsOpen}
                data={state.selectedItem}
                handleClose={handlers.handleCloseViewDialog}
              />
            }
          </CommonPage>
      }
    </>
  );
}

export default TypesOfSkillsTransversal;