import React, { useState } from "react";
import { Tooltip, IconButton, TextField, Grid, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ChangeColorDialog from "../dialogs/changeColorDialog";
const gridStyle = { height: 75, padding: "0px 10px" };

const ScoreDetail = (props) => {

  const {
    index,
    gridTheme,
    inUse,
    data,
    deleteItem,
    modifyItem,
  } = props;

  const [changeColorDialogIsOpen, setChangeColorDialogIsOpen] = useState(false);
  return (
    <>
      <div style={{ width: "100%" }}>
        <DialogTitleDivider />
      </div>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={inUse ? 12 : 11}>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <TextField
              disabled={inUse}
              required
              fullWidth
              margin={"none"}
              label={"Nombre de la nota"}
              value={data.name}
              onChange={(e) => modifyItem(index, e.target.value, 'name')}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} md={2} style={gridStyle}>
            <TextField
              disabled={inUse}
              fullWidth
              required
              margin={"none"}
              label={'Nota mínima'}
              type="number"
              value={data.minimum}
              onChange={(e) => modifyItem(index, e.target.value, 'minimum')}
              inputProps={{ min: "1", step: "0.1" }}
              onBlur={e => {
                if (e.target.value !== "") {
                  e.target.value = Number.parseFloat(e.target.value).toFixed(1);
                  modifyItem(index, e.target.value, 'minimum');
                }
              }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} md={2} style={gridStyle}>
            <TextField
              disabled={inUse}
              fullWidth
              required
              margin={"none"}
              label={'Nota máxima'}
              type="number"
              value={data.maximum}
              onChange={(e) => modifyItem(index, e.target.value, 'maximum')}
              inputProps={{ min: "0", step: "0.1" }}
              onBlur={e => {
                if (e.target.value !== "") {
                  e.target.value = Number.parseFloat(e.target.value).toFixed(1);
                  modifyItem(index, e.target.value, 'maximum');
                }
              }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} md={2} style={gridStyle}>
            <Typography className="whiteText">{"Color"}</Typography>
            <Typography variant="subtitle1" style={{ marginLeft: 5, marginRight: 10, display: "inline-block", color: "red" }}>
              *
            </Typography>
            <div onClick={() => setChangeColorDialogIsOpen(true)} style={{ border: gridTheme.theme === "dark" ? "1px solid #c4c4c4" : "1px solid black", width: 64, height: 24, backgroundColor: data.color }} />
          </Grid>
        </Grid>
        {
          !inUse &&
          <Grid container item xs={1} alignItems="center" justify="space-evenly">
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={() => deleteItem(index)}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        }
        {
          changeColorDialogIsOpen &&
          <ChangeColorDialog
            open={changeColorDialogIsOpen}
            scoreColor={data.color}
            handleCloseDialog={() => setChangeColorDialogIsOpen(false)}
            modifyItem={modifyItem}
            index={index}
          />
        }
      </Grid>
    </>
  );
}

export default ScoreDetail;
