import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.transactionLog],
        config: {
            headerName: 'Código interno',
            field: "internalCode",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.transactionLog],
        config: {
            headerName: 'Código de usuario',
            field: "Código de usuario",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.transactionLog],
        config: {
            headerName: 'Nombre del usuario',
            field: "Nombre del usuario",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.transactionLog],
        config: {
            headerName: 'Fecha de ejecución',
            field: "Fecha de ejecución",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.transactionLog],
        config: {
            headerName: 'Hora de ejecución',
            field: "Hora de ejecución",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.transactionLog],
        config: {
            headerName: 'Sentencias de SQL',
            field: "Sentencias de SQL",
            filter: "agTextColumnFilter",
        }
    },
]