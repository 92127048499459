import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'type') {
        return isCreate ? 'P' : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "name",
      "type",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const codeRegex = /^[a-zA-Z][a-zA-Z0-9_]*/;
    const nameRegex = /^[a-zA-Z][a-zA-Z0-9ÑÁÉÍÓÚ]*/;

    let schema = yup.object().shape({
      code: yup.string().matches(codeRegex).required(),
      name: yup.string().matches(nameRegex).required(),
      type: yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = { ...formData };

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;