import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import { CheckIcon } from "@icarius-icons/index";
import DuplicateSkillDialog from "./dialogs/duplicateSkillDialog";
import DuplicateGroupDialog from "./dialogs/duplicateGroupDialog";
import ViewDialog from "./dialogs/viewDialog";
import Form from "./form";
import useABM from "./useABM";

const TypesOfSkillsSpecific = ({ handleOpenParameterPage, handleGoBack }) => {

  const {
    isLoading,
    data,
    dateFormat,
    state,
    handlers,
  } = useABM();

  const [duplicateMenuAnchor, setDuplicateMenuAnchor] = useState(null);

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />

  const addItem = () =>
    <CustomIconButton
      title={"Crear"}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />

  const editItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />

  const viewItem = (gridRef) =>
    <CustomIconButton
      title={"Ver indicadores"}
      onClick={() => handlers.handleOpenViewDialog(gridRef)}
      type={"preview"}
    />

  const verifyButton = (gridRef) => (
    <CustomIconButton
      title={'Verificar ponderados'}
      onClick={() => handlers.handleVerify(gridRef)}
    >
      <CheckIcon />
    </CustomIconButton>
  )

  const duplicateItem = (gridRef) => Boolean(data?.length) &&
    <>
      <CustomIconButton
        type={'duplicate'}
        title={'Duplicar'}
        onClick={(e) => setDuplicateMenuAnchor(e.currentTarget)}
      />
      <StyledHelpMenu
        anchorEl={duplicateMenuAnchor}
        isOpen={Boolean(duplicateMenuAnchor)}
        onClose={() => setDuplicateMenuAnchor(null)}
      >
        <MenuItemWithIcon
          text={'Duplicar competencia'}
          onClick={() => {
            handlers.handleOpenDuplicateSkillDialog(gridRef);
            setDuplicateMenuAnchor(null);
          }}
        />
        <MenuItemWithIcon
          text={'Duplicar por grupo de competencia'}
          onClick={() => {
            handlers.handleOpenDuplicateGroup();
            setDuplicateMenuAnchor(null);
          }}
        />
      </StyledHelpMenu>
    </>

  const handleRowClick = (row) => {
    handleOpenParameterPage(row.data);
  }

  return (
    <>
      {
        state.createEditFormIsOpen ?
          <Form
            isLoading={isLoading}
            data={state.selectedItem}
            handleClose={handlers.handleCloseEditDialog}
            showVerificationMessage={handlers.showVerificationMessage}
          />
          :
          <CommonPage
            isLoading={isLoading}
            columnDefPage={paths.typesOfSkillsSpecific}
            title={"Competencias específicas"}
            gridTitle={"Competencias específicas"}
            menuItems={[goBackItem, addItem, editItem, deleteItem, duplicateItem, viewItem, verifyButton]}
            rowData={data}
            dateFormat={dateFormat}
            handleRowClick={handleRowClick}
            hasExpand
            hasHelp
          >
            {
              state.duplicateSkillDialogIsOpen &&
              <DuplicateSkillDialog
                open={state.duplicateSkillDialogIsOpen}
                data={state.selectedItem}
                isLoading={isLoading}
                handleConfirm={handlers.handleDuplicateSkill}
                handleClose={handlers.handleCloseDuplicateSkillDialog}
              />
            }
            {
              state.duplicateGroupDialogIsOpen &&
              <DuplicateGroupDialog
                open={state.duplicateGroupDialogIsOpen}
                data={data}
                isLoading={isLoading}
                handleConfirm={handlers.handleDuplicateGroup}
                handleClose={handlers.handleCloseDuplicateGroupDialog}
              />
            }
            {
              state.viewDialogIsOpen &&
              <ViewDialog
                open={state.viewDialogIsOpen}
                data={state.selectedItem}
                handleClose={handlers.handleCloseViewDialog}
              />
            }
          </CommonPage>
      }
    </>
  );
}

export default TypesOfSkillsSpecific;