import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getIsExportingGrid } from "src/app/selectors";
import { getTypesOfSkillsIndicatorsAction, deleteTypeOfSkillsIndicatorAction } from "../actions";
import { CLEAR_STATE } from "../actionTypes";
import { getData, getDateFormat, getIsLoading } from "../selectors";

const useABM = (code) => {

  const dispatch = useDispatch();

  const [createEditFormIsOpen, setCreateEditFormIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    dispatch(getTypesOfSkillsIndicatorsAction(code));
    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch, code])

  const data = useSelector(getData);
  const dateFormat = useSelector(getDateFormat);
  const isLoading = useSelector(getIsLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);

  const handleOpenCreateDialog = () => {
    setCreateEditFormIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un item", severity: "warning" }));
      return;
    }

    setCreateEditFormIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseEditDialog = () => {
    setCreateEditFormIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un item", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: 'Atención',
      msg: '¿Desea borrar el indicador?',
      onConfirm: () => dispatch(deleteTypeOfSkillsIndicatorAction({ internalCode: selectedRows[0].internalCode })),
    }));
  }

  const state = {
    createEditFormIsOpen,
    selectedItem,
  };

  const handlers = {
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
  };

  return {
    isLoading: isLoading || isExportinGrid,
    data,
    dateFormat,
    state,
    handlers,
  }
}

export default useABM;