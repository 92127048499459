import paths from "@icarius-localization/paths";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.typesOfBenefits],
        config: {
            headerName: 'Código del beneficio',
            field: 'code',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.typesOfBenefits],
        config: {
            headerName: 'Nombre del beneficio',
            field: 'name',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.typesOfBenefits],
        config: {
            headerName: 'Activo',
            field: 'activeString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.typesOfBenefits],
        config: {
            headerName: 'Modo de acceso',
            field: 'modeString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.typesOfBenefits],
        config: {
            headerName: 'Frecuencia de uso',
            field: 'frequencyString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.typesOfBenefits],
        config: {
            headerName: 'Puntos requeridos',
            field: 'points',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.typesOfBenefits],
        config: {
            headerName: 'Valor del beneficio',
            field: 'value',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.typesOfBenefits],
        config: {
            headerName: 'Concepto de pago relacionado',
            field: 'conceptString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.typesOfBenefits],
        config: {
            headerName: 'Presupuesto máximo del beneficio',
            field: 'maxBenefitBudget',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]