import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import useTypesOfBenefits from "./useTypesOfBenefits";
import CreateEditDialog from "./dialogs/createEditDialog/index";

const TypesOfBenefits = ({ history }) => {

  const {
    data,
    modeList,
    conceptList,
    frequencyList,
    daysAuthorizedList,
    isLoading,
    isAdmin,
    state,
    handlers
  } = useTypesOfBenefits();

  const createButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog(gridRef)}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.benefitsDefinitions)}
      type={"goBack"}
    />

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Tipos de beneficios'}
      gridTitle={'Tipos de beneficios'}
      columnDefPage={paths.typesOfBenefits}
      rowData={data}
      menuItems={[goBackItem, createButton, modifyButton, deleteButton]}
      hasExpand
      hasHelp
      handleRowClick={handlers.handleOpenViewDialog}
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen || state.viewDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen || state.viewDialogIsOpen)}
          loading={isLoading}
          data={state.selectedDocument}
          handleClose={handlers.handleCloseCreateEditDuplicateDialog}
          modeList={modeList}
          conceptList={conceptList}
          daysAuthorizedList={daysAuthorizedList}
          frequencyList={frequencyList}
          isAdmin={isAdmin}
          isView={state.viewDialogIsOpen}
        />
      }
    </CommonPage>
  )
}

export default TypesOfBenefits;