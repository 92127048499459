import {
	numberComparatorMax2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: ['typesOfSkillsSpecificIndicators'],
        config: {
            headerName: "Código de competencia",
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['typesOfSkillsSpecificIndicators'],
        config: {
            headerName: "Número de indicador",
            field: "indicatorNumber",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            cellClass: "currencyValueNoDecimal",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['typesOfSkillsSpecificIndicators'],
        config: {
            headerName: "Nombre del indicador",
            field: "indicatorName",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['typesOfSkillsSpecificIndicators'],
        config: {
            headerName: 'Descripción del comportamiento',
            field: "behaviorDescription",
            filter: "agTextColumnFilter",
        }
    },
]