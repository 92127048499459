import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import { useSelector } from "react-redux";
import { getData } from "@icarius-pages/typesOfSkillsSpecificIndicators/selectors";
import { withStyles } from "@material-ui/core/styles";

const gridStyle = { padding: 10 };

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
  },
})(Dialog);

const ViewDialog = (props) => {

  const {
    open,
    data,
    handleClose,
  } = props;

  const indicators = useSelector(getData);

  return (
    <StyledDialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {'Visualización con indicadores'}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item xs={12}>
          <Grid container item direction="column" xs={12} sm={6} justify="center" style={gridStyle}>
            <Typography className="whiteText" variant="caption">
              {'Nombre de la competencia'}
            </Typography>
            <Typography className="whiteText">
              {data.name}
            </Typography>
          </Grid>
          <Grid container item direction="column" xs={12} justify="center" style={gridStyle}>
            <Typography className="whiteText" variant="caption">
              {'Grupo de competencia'}
            </Typography>
            <Typography className="whiteText">
              {data.group}
            </Typography>
          </Grid>
          <Grid container item direction="column" xs={12} justify="center" style={gridStyle}>
            <Typography className="whiteText" variant="caption">
              {'Descripción'}
            </Typography>
            <Typography className="whiteText">
              {
                data.description.split(/\r\n|\r|\n|\/n/).map((item, key) => {
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  );
                })
              }
            </Typography>
          </Grid>
          <Grid container item direction="column" xs={12} justify="center" style={gridStyle}>
            {
              Boolean(indicators.length) ?
                <>
                  <Typography className="whiteText" variant="caption">
                    {'Indicadores'}
                  </Typography>
                  <div style={{ overflow: 'auto' }}>
                    {
                      indicators.map((item) => {
                        return (
                          <>
                            <Typography key={item.internalCode} className="whiteText">
                              {`${item.indicatorNumber}. ${item.indicatorName}`}
                            </Typography>
                            <Typography key={item.internalCode} className="whiteText" style={{ fontSize: 14, marginLeft: 25 }}>
                              {item.behaviorDescription}
                            </Typography>
                          </>
                        )
                      })
                    }
                  </div>
                </>
                :
                <Typography className="whiteText" style={{ fontSize: 18, fontWeight: 600 }}>
                  No posee indicadores
                </Typography>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction isAccept onClick={handleClose} text={getLocalizedString("close")} />
      </DialogActions>
    </StyledDialog>
  );
}

export default ViewDialog;