import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import { LanguagesIcon, SpecializationsIcon, StudiesIcon, SchoolingIcon } from "@icarius-icons/";

const TrainingData = () => {

  const data = [
    {
      name: "Escolaridades",
      icon: <SchoolingIcon />,
      path: paths.schooling,
    },
    {
      name: "Estudios",
      icon: <StudiesIcon />,
      path: paths.studies,
    },
    {
      name: "Especializaciones",
      icon: <SpecializationsIcon />,
      path: paths.specializations,
    },
    {
      name: "Idiomas",
      icon: <LanguagesIcon />,
      path: paths.languages,
    },
  ];

  return (
    <ScreenSelector
      title={"Definición de formación"}
      data={data}
    />
  );
}

export default TrainingData;
