import * as actionTypes from "./actionTypes";
import {
  getTypesOfBenefitsAPI,
  deleteTypesOfBenefitsAPI,
  createTypesOfBenefitsAPI,
  modifyTypesOfBenefitsAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { openDialogAction } from "@icarius-common/dialog/actions";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'El código del tipo de beneficio ingresado ya existe';
      break;
    case "IN_USE_ORG":
      errorString = 'No es posible eliminar el beneficio porque está habilitado como Beneficio Organizacional'
      break;
    case "IN_USE_PLAN":
      errorString = 'No es posible eliminar el beneficio porque está relacionado con un Plan de Reconocimiento';
      break;
    case "IN_USE_CTA":
      errorString = 'No es posible eliminar el beneficio porque ya se lo ha utilizado. Puede dejarlo como Inactivo pero no eliminarlo';
      break;
    case "IN_USE":
      dispatch(openDialogAction({
        title: "Atención",
        msg: 'Este beneficio ya existe como Beneficio Organizacional y no es posible: Dejarlo inactivo, Asignarle puntos o definirlo con modo de acceso Mediante canje por puntos. Primero debe eliminarlo de los Beneficios Organizacionales para luego proceder con estos cambios',
        acceptOnly: true
      }));
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  if (e.response?.data?.status.includes("IN_USE_")) {
    dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: null }));
  } else if (e.response?.data?.status !== "IN_USE") {
    dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
  }
}

export const getTypesOfBenefitsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getTypesOfBenefitsAPI();

    let data = response.data && response.data.data;
    let modeList = response.data && response.data.modeList;
    let frequencyList = response.data && response.data.frequencyList;
    let conceptList = response.data && response.data.conceptList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, modeList, conceptList, frequencyList },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteTypesOfBenefitsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteTypesOfBenefitsAPI(dataToSend);

    let data = response.data && response.data.data;
    let modeList = response.data && response.data.modeList;
    let frequencyList = response.data && response.data.frequencyList;
    let conceptList = response.data && response.data.conceptList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data, modeList, conceptList, frequencyList },
    });

    dispatch(openSnackbarAction({ msg: 'Tipo de beneficio eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createTypesOfBenefitsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createTypesOfBenefitsAPI(dataToSend);

    let data = response.data && response.data.data;
    let modeList = response.data && response.data.modeList;
    let frequencyList = response.data && response.data.frequencyList;
    let conceptList = response.data && response.data.conceptList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data, modeList, conceptList, frequencyList },
    });

    dispatch(openSnackbarAction({ msg: 'Tipo de beneficio creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyTypesOfBenefitsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyTypesOfBenefitsAPI(dataToSend);

    let data = response.data && response.data.data;
    let modeList = response.data && response.data.modeList;
    let frequencyList = response.data && response.data.frequencyList;
    let conceptList = response.data && response.data.conceptList; let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data, modeList, conceptList, frequencyList },
    });

    dispatch(openSnackbarAction({ msg: 'Tipo de beneficio actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};
