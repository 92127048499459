import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, groupList, duplicateCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = () => {
      return "";
    }

    const fieldNames = [
      "ref",
      "group",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    let schema = yup.object().shape({
      ref: yup.string().required(),
      group: yup.string().required(),
    });

    const isSchemaValid = await schema.isValid(formData).then((valid) => valid);
    if (!isSchemaValid) return false;
    if (groupList.some((item) => item.key === formData.group)) return false;
    return true;
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = { ...formData };

      duplicateCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;