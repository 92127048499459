import NAME from "./constants";

export const GET = NAME + "/GET";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";
export const GET_REJECTED = NAME + "/GET_REJECTED";

export const CREATE = NAME + "/CREATE";
export const CREATE_FULFILLED = NAME + "/CREATE_FULFILLED";
export const CREATE_REJECTED = NAME + "/CREATE_REJECTED";

export const MODIFY = NAME + "/MODIFY";
export const MODIFY_FULFILLED = NAME + "/MODIFY_FULFILLED";
export const MODIFY_REJECTED = NAME + "/MODIFY_REJECTED";

export const DELETE = NAME + "/DELETE";
export const DELETE_FULFILLED = NAME + "/DELETE_FULFILLED";
export const DELETE_REJECTED = NAME + "/DELETE_REJECTED";

export const DUPLICATE = NAME + "/DUPLICATE";
export const DUPLICATE_FULFILLED = NAME + "/DUPLICATE_FULFILLED";
export const DUPLICATE_REJECTED = NAME + "/DUPLICATE_REJECTED";

export const VERIFY = NAME + "/VERIFY";
export const VERIFY_REJECTED = NAME + "/VERIFY_REJECTED";
export const VERIFY_FULFILLED = NAME + "/VERIFY_FULFILLED";

export const CLEAR_STATE = NAME + "/CLEAR_STATE";