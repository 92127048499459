import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { withStyles } from "@material-ui/core/styles";
import { TextField, Grid } from "@material-ui/core";
import { createTypeOfSkillsAction, updateTypeOfSkillsAction } from "../../actions";
import useHandleForm from "./useHandleForm";
import { useDispatch } from "react-redux";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {

  const {
    isLoading,
    data,
    classes,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleClose}
      type={"goBack"}
    />

  const create = (formData) => {
    dispatch(createTypeOfSkillsAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (formData) => {
    dispatch(updateTypeOfSkillsAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <CommonPage
      isLoading={isLoading}
      title={`${isCreate ? 'Crear' : 'Editar'} competencia transversal`}
      menuItems={[goBackItem]}
      isNotGridPage
    >
      <Grid container item xs={12} direction="row" style={{ width: "95%", margin: "auto" }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nombre de la competencia"}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, "name")}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}>
            <TextField
              required
              fullWidth
              label="Descripción"
              variant="outlined"
              multiline
              rows={10}
              margin={"none"}
              value={formData.description}
              onChange={(e) => setFormValue(e.target.value, "description")}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              inputProps={{ spellCheck: 'false' }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ marginBottom: 35 }}>
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
    </CommonPage>
  )
}

export default withStyles(styles)(Form);