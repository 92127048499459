import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getIsExportingGrid } from "src/app/selectors";
import {
  getTypesOfSkillsAction,
  deleteTypeOfSkillsAction,
  duplicateTypeOfSkillsAction,
} from "../actions";
import { CLEAR_STATE } from "../actionTypes";
import { getData, getDateFormat, getIsLoading } from "../selectors";
import { getTypesOfSkillsIndicatorsAction } from "@icarius-pages/typesOfSkillsTransversalIndicators/actions";
import { CLEAR_STATE as CLEAR_STATE_INDICATORS } from "@icarius-pages/typesOfSkillsTransversalIndicators/actionTypes";
import { getIsLoading as getIsLoadingIndicators } from "@icarius-pages/typesOfSkillsTransversalIndicators/selectors";

const useABM = () => {

  const dispatch = useDispatch();

  const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);
  const [copyGroupDialogIsOpen, setCopyGroupDialogIsOpen] = useState(false);
  const [duplicateSkillDialogIsOpen, setDuplicateSkillDialogIsOpen] = useState(false);
  const [createEditFormIsOpen, setCreateEditFormIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    dispatch(getTypesOfSkillsAction());
    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch])

  const data = useSelector(getData);
  const dateFormat = useSelector(getDateFormat);
  const isLoading = useSelector(getIsLoading);
  const isLoadingIndicators = useSelector(getIsLoadingIndicators);
  const isExportinGrid = useSelector(getIsExportingGrid);

  const handleOpenCreateDialog = () => {
    setCreateEditFormIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un item", severity: "warning" }));
      return;
    }

    setCreateEditFormIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseEditDialog = () => {
    setCreateEditFormIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un item", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: 'Atención',
      msg: '¿Desea borrar la competencia?',
      onConfirm: () => dispatch(deleteTypeOfSkillsAction({ code: selectedRows[0].code })),
    }));
  }

  const handleOpenCopyGroupDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos un item", severity: "warning" }));
      return;
    }

    if (selectedRows.some((item) => item.type !== 'E')) {
      dispatch(openSnackbarAction({ msg: "Las competencias seleccionadas deben ser de tipo Específica", severity: "warning", duration: 10000 }));
      return;
    }

    setCopyGroupDialogIsOpen(true);
    setSelectedItem(selectedRows.map((item) => item.code));
  }

  const handleCloseCopyGroupDialog = () => {
    setCopyGroupDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleDuplicateSkill = (dataToSend) => {
    dispatch(duplicateTypeOfSkillsAction(dataToSend))
      .then(() => {
        handleCloseDuplicateSkillDialog();
      })
  }

  const handleOpenDuplicateSkillDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un item", severity: "warning" }));
      return;
    }

    setDuplicateSkillDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  }

  const handleCloseDuplicateSkillDialog = () => {
    setDuplicateSkillDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenViewDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un item", severity: "warning" }));
      return;
    }

    dispatch(getTypesOfSkillsIndicatorsAction(selectedRows[0].code))
      .then((resp) => {
        if (resp.status === 200) {
          setSelectedItem(selectedRows[0]);
          setViewDialogIsOpen(true);
        }
      })
  }

  const handleCloseViewDialog = () => {
    setViewDialogIsOpen(false);
    setSelectedItem(null);
    dispatch({ type: CLEAR_STATE_INDICATORS });
  }

  const state = {
    createEditFormIsOpen,
    viewDialogIsOpen,
    copyGroupDialogIsOpen,
    duplicateSkillDialogIsOpen,
    selectedItem,
  };

  const handlers = {
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,

    handleOpenDuplicateSkillDialog,
    handleCloseDuplicateSkillDialog,
    handleDuplicateSkill,

    handleOpenCopyGroupDialog,
    handleCloseCopyGroupDialog,

    handleOpenViewDialog,
    handleCloseViewDialog,
  };

  return {
    isLoading: isLoading || isExportinGrid || isLoadingIndicators,
    data,
    dateFormat,
    state,
    handlers,
  }
}

export default useABM;