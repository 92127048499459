import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'active') {
        return isCreate ? true : data[fieldName];
      }

      if (fieldName === 'daysAuthorized') {
        return isCreate ? 0 : Number(data[fieldName]);
      }

      if (fieldName === 'active') {
        return isCreate ? true : data[fieldName];
      }

      if (fieldName === 'mode') {
        return isCreate ? 'CP' : data[fieldName];
      }

      if (fieldName === 'frequency') {
        return isCreate ? 'SU' : data[fieldName];
      }

      if (fieldName === 'points' || fieldName === 'maxBenefitBudget' || fieldName === 'value') {
        return isCreate ? '0' : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'code',
      'name',
      'active',
      'mode',
      'description',
      'requisites',
      'frequency',
      'points',
      'value',
      'concept',
      'sql',
      'image',
      'daysAuthorized',
      'maxBenefitBudget',
      'link',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const regex = /^([A-Za-z])([A-Za-z]|[0-9]|_){0,10}$/;
    const regexAlphanumeric = /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ\s]*/;

    const schema = yup.object().shape({
      'code': yup.string().max(20).matches(regex).required(),
      'name': yup.string().max(100).matches(regexAlphanumeric).required(),
      'active': yup.boolean().required(),
      'mode': yup.string().max(2).required(),
      'description': yup.string().required(),
      'requisites': yup.string().optional(),
      'link': yup.string().optional(),
      'frequency': yup.string().max(2),
      'points': yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      'daysAuthorized': yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      'maxBenefitBudget': yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      'value': yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      'concept': yup.string().max(20).optional(),
      'sql': yup.string().optional(),
      'image': yup.string(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        ...formData,
        value: Number(formData.value),
        points: Number(formData.points),
        daysAuthorized: Number(formData.daysAuthorized),
        maxBenefitBudget: Number(formData.maxBenefitBudget),
      };

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
