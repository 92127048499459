import React from "react";
import { useDispatch } from "react-redux";
import { TextField, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import FabButton from "@icarius-common/fabButton";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import ScoreDetail from "./scoreDetail";
import { createTypesOfScoreAction, modifyTypesOfScoreAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const {
    data,
    typeOfScoreNames,
    gridTheme,
    typeOfScoreCodes,
    closeTypeOfScore,
  } = props;

  const dispatch = useDispatch();

  const createTypeOfScore = (dataToSend) => {
    dispatch(createTypesOfScoreAction(dataToSend))
      .then((res) => res?.status === 200 && closeTypeOfScore());
  }

  const modifyTypeOfScore = (dataToSend) => {
    dispatch(modifyTypesOfScoreAction(dataToSend))
      .then((res) => res?.status === 200 && closeTypeOfScore());
  }

  const openValidationError = (error) => {
    let msg = "Todos los campos son obligatorios y debe haber al menos una nota";
    if (error) {
      msg = error;
    }
    
    dispatch(openSnackbarAction({ msg, severity: "error", duration: 10000 }));
  }

  const openNameValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'Ya existe un tipo de nota con el nombre ingresado', severity: "error" }));
  }

  const openCodeValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'Ya existe un tipo de nota con el código ingresado', severity: "error" }));
  }

  const invalidDetailsNamesCallback = () => {
    dispatch(openSnackbarAction({ msg: 'No puede haber notas con el mismo nombre', severity: "error" }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    createItem,
    deleteItem,
    modifyItem,
    submit,
  } = useHandleForm(data, createTypeOfScore, modifyTypeOfScore, openValidationError, openNameValidationError, false, typeOfScoreNames, typeOfScoreCodes, openCodeValidationError, invalidDetailsNamesCallback);

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <TextField
              disabled={data?.inUse || !isCreate}
              required
              fullWidth
              margin={"none"}
              label={"Código del tipo de nota"}
              value={formData.code}
              onChange={(e) => setFormValue(e.target.value, "code")}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <TextField
              disabled={data?.inUse}
              required
              fullWidth
              margin={"none"}
              label={"Nombre del tipo de nota"}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, "name")}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <TextField
              disabled={data?.inUse}
              fullWidth
              required
              margin={"none"}
              label={'Nota máxima'}
              type="number"
              value={formData.maxScore}
              onChange={(e) => setFormValue(e.target.value, 'maxScore')}
              inputProps={{ min: "0.1", step: "0.1" }}
              onBlur={e => {
                if (e.target.value !== "") {
                  e.target.value = Number.parseFloat(e.target.value).toFixed(1);
                  setFormValue(e.target.value, 'maxScore');
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          {
            formData.details?.map((item, index) =>
              <ScoreDetail
                key={index}
                inUse={data?.inUse}
                index={index}
                gridTheme={gridTheme}
                data={item}
                hideDuplicate={formData.type === 'N'}
                deleteItem={deleteItem}
                modifyItem={modifyItem}
              />
            )
          }
        </Grid>
        <Grid container item xs={12} justify={data?.inUse ? "flex-end" : "space-between"} alignItems="center" style={{ paddingBottom: 20 }}>
          {
            !data?.inUse &&
            <FabButton onClick={createItem} title={"Nueva nota"} />
          }
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
    </>
  );
}

export default Form;
