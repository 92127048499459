import React, { useEffect, useState } from "react";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import DialogTransition from "@icarius-common/dialogTransition";
import { GalleryDialog } from "@icarius-common/galleryDialog";
import {
  deleteImageAction,
  getGalleryImagesAction,
  uploadImageAction
} from "@icarius-common/galleryDialog/actions";
import useSetImageIndex from "@icarius-common/galleryDialog/components/useSetImageIndex";
import { getGalleryImages } from "@icarius-common/galleryDialog/selectors";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { CloseIcon } from "@icarius-icons";
import { ImagePlaceholder } from "@icarius-icons/index";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { createTypesOfBenefitsAction, modifyTypesOfBenefitsAction } from "../../../actions";
import NumberFormatCustom from './numberFormatCustom';
import NumberFormatCustomNoDecimals from "./numberFormatCustomNoDecimals";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };
const gridStyleNoHeight = { padding: "0px 10px", marginTop: "15px" };
const flexStyle = { height: 50, padding: "0px 10px", display: "flex", alignItems: "center", cursor: "pointer" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});


const CreateEditDialog = (props) => {

  const { open, isAdmin, isView, modeList, conceptList, frequencyList, daysAuthorizedList, loading, data, handleClose, classes } = props;

  const dispatch = useDispatch();
  const images = useSelector(getGalleryImages);
  const color = useSelector(getAppColor);
  const [imageIndex, setImageIndex] = useSetImageIndex(data, images);
  const [imagesDialogIsOpen, setImagesDialogIsOpen] = useState(false);

  useEffect(() => {
    dispatch(getGalleryImagesAction('news'));
  }, [dispatch])

  const uploadImage = (file) => {
    setImageIndex(null);
    dispatch(uploadImageAction(file, 'news'));
  }

  const deleteImage = (filename) => {
    setImageIndex(null);
    dispatch(deleteImageAction(filename, 'news'));
  }

  const create = (dataToSend) => {
    if (dataToSend.mode !== "SM" && dataToSend.daysAuthorized) {
      dispatch(openSnackbarAction({
        msg: "Si ingresa días de ausencia autorizados debe definir como modo de acceso vía solicitud al manager",
        severity: "error",
        duration: null,
      }));
      return;
    }


    if (Boolean(dataToSend.concept) && dataToSend.mode !== "SE") {
      dispatch(openSnackbarAction({
        msg: "Si ingresa concepto de pago relacionado debe definir como modo de acceso vía solicitud al empleador",
        severity: "error",
        duration: null,
      }));
      return;
    }

    if (dataToSend.mode === "CP" && (!dataToSend.points || !parseInt(dataToSend.points))) {
      dispatch(openSnackbarAction({
        msg: "Para definir modo de acceso mediante canje por puntos es necesario que ingrese los puntos requeridos",
        severity: "error",
        duration: null,
      }));
      return;
    }

    if (dataToSend.mode === "LD" && (dataToSend.points || dataToSend.daysAuthorized || dataToSend.concept)) {
      dispatch(openSnackbarAction({
        msg: "Para definir modo de acceso con libre disposición no puede tener puntos, ni días de ausencia autorizados ni concepto de pago relacionado",
        severity: "error",
        duration: null,
      }));
      return;
    }

    if (dataToSend.mode === "SM" && !dataToSend.daysAuthorized) {
      dispatch(openSnackbarAction({
        msg: "Para definir modo de acceso vía solicitud al manager debe tener días de ausencia autorizados",
        severity: "error",
        duration: null,
      }));
      return;
    }

    if (Boolean(dataToSend.concept) && (!dataToSend.value || dataToSend.value === "0.00" || dataToSend.value === 0.00)) {
      dispatch(openSnackbarAction({
        msg: "Para definir un concepto de pago remunerativo asociado al beneficio, el valor del beneficio debe ser mayor a cero",
        severity: "error",
        duration: null,
      }));
      return;
    }

    dispatch(createTypesOfBenefitsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {

    if (dataToSend.mode !== "SM" && dataToSend.daysAuthorized) {
      dispatch(openSnackbarAction({
        msg: "Si ingresa días de ausencia autorizados debe definir como modo de acceso vía solicitud al manager",
        severity: "error",
        duration: null,
      }));
      return;
    }


    if (Boolean(dataToSend.concept) && dataToSend.mode !== "SE") {
      dispatch(openSnackbarAction({
        msg: "Si ingresa concepto de pago relacionado debe definir como modo de acceso vía solicitud al empleador",
        severity: "error",
        duration: null,
      }));
      return;
    }

    if (dataToSend.mode === "CP" && (!dataToSend.points || !parseInt(dataToSend.points))) {
      dispatch(openSnackbarAction({
        msg: "Para definir modo de acceso mediante canje por puntos es necesario que ingrese los puntos requeridos",
        severity: "error",
        duration: null,
      }));
      return;
    }

    if (dataToSend.mode === "LD" && (dataToSend.points || dataToSend.daysAuthorized || dataToSend.concept)) {
      dispatch(openSnackbarAction({
        msg: "Para definir modo de acceso con libre disposición no puede tener puntos, ni días de ausencia autorizados ni concepto de pago relacionado",
        severity: "error",
        duration: null,
      }));
      return;
    }


    if (dataToSend.mode === "SM" && !dataToSend.daysAuthorized) {
      dispatch(openSnackbarAction({
        msg: "Para definir modo de acceso vía solicitud al manager debe tener días de ausencia autorizados",
        severity: "error",
        duration: null,
      }));
      return;
    }



    if (Boolean(dataToSend.concept) && (!dataToSend.value || dataToSend.value === "0.00" || dataToSend.value === 0.00)) {
      dispatch(openSnackbarAction({
        msg: "Para definir un concepto de pago remunerativo asociado al beneficio, el valor del beneficio debe ser mayor a cero",
        severity: "error",
        duration: null,
      }));
      return;
    }
    dispatch(modifyTypesOfBenefitsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, modify, openValidationError);

  const handleSelectImage = (index) => {
    setFormValue(index !== null ? images[index].img : "", "image");
    setImageIndex(index);
  }

  if (loading) return null;

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        maxWidth={"md"}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {isView ? "Ver tipo de beneficio" : isCreate ? "Crear tipo de beneficio" : "Editar tipo de beneficio"}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent>
            <Grid container item xs={12}>
              <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* code */}
                <TextField
                  disabled={isView || !isCreate}
                  fullWidth
                  required
                  margin={"none"}
                  label={'Código del beneficio'}
                  value={formData.code}
                  onChange={(e) => setFormValue(e.target.value, "code")}
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
              <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* name */}
                <TextField
                  disabled={isView}
                  fullWidth
                  required
                  margin={"none"}
                  label={'Nombre del beneficio'}
                  value={formData.name}
                  onChange={(e) => setFormValue(e.target.value, "name")}
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* mode */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-entry`}>{"Modo de acceso"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.mode}
                    labelId={`label-mode`}
                    id={`select-mode`}
                    onChange={(e) => setFormValue(e.target.value, "mode")}
                    margin={"none"}
                  >
                    {
                      modeList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* daysAuthorized */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-daysAuthorized`}>{"Días de ausencia autorizados"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.daysAuthorized}
                    labelId={`label-daysAuthorized`}
                    id={`select-daysAuthorized`}
                    onChange={(e) => setFormValue(e.target.value, "daysAuthorized")}
                    margin={"none"}
                  >
                    {
                      daysAuthorizedList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <div style={flexStyle} onClick={isView ? null : () => setFormValue(!Boolean(formData.active), "active")}>
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {"Activo"}
                  </Typography>
                  <Checkbox checked={Boolean(formData.active)} />
                </div>
              </div>
              <Grid container item alignItems="center" xs={12} style={gridStyleNoHeight}> {/* description */}
                <TextField
                  disabled={isView}
                  required
                  fullWidth
                  margin={"none"}
                  label={'Descripción del beneficio'}
                  value={formData.description}
                  onChange={(e) => setFormValue(e.target.value, "description")}
                  multiline={true}
                  variant="outlined"
                  rows={5}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    spellCheck: 'false',
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                />
              </Grid>
              <Grid container item alignItems="center" xs={12} style={gridStyleNoHeight}> {/* requisites */}
                <TextField
                  disabled={isView}
                  fullWidth
                  margin={"none"}
                  variant="outlined"
                  label={'Requisitos y condiciones'}
                  value={formData.requisites}
                  onChange={(e) => setFormValue(e.target.value, "requisites")}
                  multiline={true}
                  rows={5}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    spellCheck: 'false',
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                />
              </Grid>
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* frequency */}
                <FormControl style={{ width: "100%" }}>
                  <InputLabel required id={`label-frequency`}>{"Frecuencia de uso"}</InputLabel>
                  <Select
                    disabled={isView}
                    value={formData.frequency}
                    labelId={`label-frequency`}
                    id={`select-frequency`}
                    onChange={(e) => setFormValue(e.target.value, "frequency")}
                    margin={"none"}
                  >
                    {
                      frequencyList.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}> {/* points */}
                <TextField
                  disabled={isView}
                  fullWidth
                  margin={"none"}
                  label={'Puntos requeridos'}
                  value={formData.points}
                  onChange={(e) => setFormValue(e.target.value, "points")}
                  InputProps={{ inputComponent: NumberFormatCustomNoDecimals, min: "1", step: "0.1" }}
                />
              </Grid>
              <Grid container direction="row" justify="center" alignItems="flex-start">
                <Grid container item alignItems="center" xs={12} sm={6} style={Boolean(formData.concept) && Number(formData.value) <= 0 ? { ...gridStyle, height: "auto" } : gridStyle}> {/* concept */}
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id={`label-concept`}>{"Concepto de pago relacionado"}</InputLabel>
                    <Select
                      disabled={isView}
                      value={formData.concept}
                      labelId={`label-concept`}
                      id={`select-concept`}
                      onChange={(e) => setFormValue(e.target.value, "concept")}
                      margin={"none"}
                    >
                      <MenuItem className={"whiteText"} value={""}>
                        {""}
                      </MenuItem>
                      {
                        conceptList.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}>
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item alignItems="center" xs={12} md={6} style={gridStyle} > {/* value */}
                  <TextField
                    fullWidth
                    required={Boolean(formData.concept)}
                    disabled={isView}
                    margin={"none"}
                    label={'Valor del beneficio'}
                    value={formData.value}
                    onChange={(e) => setFormValue(e.target.value, "value")}
                    InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
                    helperText={Boolean(formData.concept) && Number(formData.value) <= 0 && 'Para definir un concepto de pago remunerativo asociado al beneficio, el valor del beneficio debe ser mayor a cero'}
                  />
                </Grid>
              </Grid>
              <Grid container item alignItems="center" xs={12} md={6} style={gridStyle} > {/* maxBenefitBudget */}
                <TextField
                  fullWidth
                  disabled={isView || (!isView && Number(formData.value) <= 0)}
                  margin={"none"}
                  label={'Presupuesto máximo del beneficio'}
                  value={formData.maxBenefitBudget}
                  onChange={(e) => setFormValue(e.target.value, "maxBenefitBudget")}
                  InputProps={{ inputComponent: NumberFormatCustom, min: "1", step: "0.1" }}
                />
              </Grid>
              <Grid container item alignItems="center" xs={12} md={6} style={gridStyle} > {/* link */}
                <TextField
                  fullWidth
                  margin={"none"}
                  label={"Link"}
                  value={formData.link}
                  onChange={(e) => setFormValue(e.target.value, "link")}
                  inputProps={{ maxLength: 254 }}
                />
              </Grid>
              {
                isAdmin &&
                <Grid container item alignItems="center" xs={12} style={gridStyleNoHeight}> {/* sql */}
                  <TextField
                    disabled={isView}
                    fullWidth
                    margin={"none"}
                    variant="outlined"
                    label={'SQL condicional'}
                    value={formData.sql}
                    onChange={(e) => setFormValue(e.target.value, "sql")}
                    multiline={true}
                    helperText={'Para poder realizar filtros en el SQL condicional recuerde que dispone de las variables #CODEMP# y #EMPLOYEE# las cuales contendrán el código del colaborador conectado y que es quién visualizará los beneficios disponibles'}
                    rows={5}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      spellCheck: 'false',
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      }
                    }}
                  />
                </Grid>
              }
              <Grid container item xs={12} style={{ width: 220, height: 140, marginTop: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
                {
                  imageIndex !== null ?
                    <img
                      style={{ maxWidth: 220, maxHeight: 140 }}
                      src={images && images.length > 0 ? IMAGES_ENDPOINT + images[imageIndex].img : ""}
                      alt={"imageNotFound"}
                    />
                    :
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 220, height: 140, backgroundColor: color }}>
                      <ImagePlaceholder fontSize="small" style={{ fill: "var(--icons)" }} />
                    </div>
                }
              </Grid>
              {
                !isView &&
                <div style={{ marginTop: 10, marginBottom: 10, width: "100%", display: 'flex', justifyContent: 'center' }} onClick={() => setImagesDialogIsOpen(true)}>
                  <Typography variant="h6" className="whiteText" style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}>
                    {getLocalizedString("groupImagesTitle")}
                  </Typography>
                </div>
              }
            </Grid>
          </DialogContent>
          {
            !isView &&
            <DialogActions>
              <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
              <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
            </DialogActions>
          }
        </div>
      </Dialog>
      {
        imagesDialogIsOpen &&
        <GalleryDialog
          imagePreselected={imageIndex}
          open={imagesDialogIsOpen}
          handleClose={() => setImagesDialogIsOpen(false)}
          handleSubmit={(img) => handleSelectImage(img)}
          handleUploadImage={uploadImage}
          handleDeleteUserImage={deleteImage}
        />
      }
    </>
  );
}

export default withStyles(styles)(CreateEditDialog);
