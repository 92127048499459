import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid } from "@material-ui/core";
import Item from "./typeOfScore/item";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import useTypesOfScore from "./useTypesOfScore";

const TypesOfScore = ({ history }) => {

  const {
    color,
    gridTheme,
    data,
    isLoading,
    createTypeOfScoreIsOpen,
    setCreateTypeOfScoreIsOpen,
  } = useTypesOfScore();

  const customItems = (
    <>
      <CustomIconButton
        title={getLocalizedString("goBack")}
        onClick={() => history.push(paths.performanceDefinition)}
        type={"goBack"}
      />
    </>
  )

  const typeOfScoreNames = data?.map(typeOfScore => typeOfScore.name) || [];
  const typeOfScoreCodes = data?.map(typeOfScore => typeOfScore.code) || [];

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  return (
    <ABMLayout
      title={"Notas para evaluaciones"}
      isLoading={isLoading}
      showCreateButton={!createTypeOfScoreIsOpen}
      createButtonTitle={"Nuevo tipo de nota"}
      createButtonOnClick={() => setCreateTypeOfScoreIsOpen(true)}
      customItems={[customItems]}
      useCustomItemsEnd
      showNoData={getDataIsEmpty()}
      noDataTitle={"No se encontraron tipos de notas"}
      noDataCreateTitle={"Nuevo tipo de nota"}
      noDataOnClick={() => setCreateTypeOfScoreIsOpen(true)}
    >
      {
        createTypeOfScoreIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <Item
            data={null}
            color={color}
            closeNewTypeOfScore={() => setCreateTypeOfScoreIsOpen(false)}
            isLoading={isLoading}
            typeOfScoreNames={typeOfScoreNames}
            typeOfScoreCodes={typeOfScoreCodes}
            gridTheme={gridTheme}
          />
        </Grid>
      }
      {
        data.map((item) =>
          <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
            <Item
              data={item}
              color={color}
              isLoading={isLoading}
              typeOfScoreNames={typeOfScoreNames}
              typeOfScoreCodes={typeOfScoreCodes}
              gridTheme={gridTheme}
            />
          </Grid>
        )
      }
    </ABMLayout>
  )
}

export default TypesOfScore;