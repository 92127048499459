import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import Form from "./form";
import { deleteTypesOfScoreAction } from "../../actions";
import DeleteDialog from "../dialogs/deleteDialog";
import DataContainer from "@icarius-common/abmComponents/dataContainer";

const Item = (props) => {

  const {
    data,
    typeOfScoreNames,
    roles,
    modes,
    processList,
    typeOfScoreTypes,
    typeOfScoreCodes,
    color,
    gridTheme,
    closeNewTypeOfScore,
  } = props;

  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);

  const handleDeleteTypeOfScore = () => {
    if (isCreate) {
      closeNewTypeOfScore();
    } else {
      dispatch(deleteTypesOfScoreAction(data.code, data.name));
    }
  }

  const handleCloseTypeOfScore = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={10} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleCloseTypeOfScore}>
            {
              isCreate ?
                <DataContainer sm={12} md={12} lg={12} text={"Creación de tipo de nota"} />
                :
                <>
                  <DataContainer lg={4} md={4} sm={4} text={data.code} />
                  <DataContainer lg={4} md={4} sm={4} text={data.name} />
                  <DataContainer lg={2} md={2} sm={4} text={`Nota máxima: ${parseFloat(data.maxScore).toFixed(1)}`} />
                  <DataContainer lg={2} md={2} sm={4} text={`Notas: ${parseInt(data.details.length)}`} />
                </>
            }
          </Grid>
          <Grid container direction="row" item xs={2} justify={isCreate ? "flex-end" : "space-around"}>
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={() => setDeleteDialogIsOpen(true)}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          closeTypeOfScore={isCreate ? closeNewTypeOfScore : handleCloseTypeOfScore}
          data={data}
          roles={roles}
          modes={modes}
          gridTheme={gridTheme}
          processList={processList}
          typeOfScoreTypes={typeOfScoreTypes}
          typeOfScoreNames={typeOfScoreNames}
          typeOfScoreCodes={typeOfScoreCodes}
        />
      }
      {
        deleteDialogIsOpen &&
        <DeleteDialog
          open={deleteDialogIsOpen}
          typeOfScore={data}
          handleClose={() => setDeleteDialogIsOpen(false)} handleDelete={handleDeleteTypeOfScore}
        />
      }
    </>
  );
}

export default Item;
