import React, { useState } from "react";
import TypesOfSkillsSpecific from "./typesOfSkillsSpecific";
import paths from "@icarius-localization/paths";
import { TypesOfSkillsSpecificIndicators } from "@icarius-pages/typesOfSkillsSpecificIndicators";

const TypesOfSkillsSwitch = ({ history }) => {

  const [parametersIsOpen, setParametersIsOpen] = useState(false);
  const [parameter, setParameter] = useState(null);

  const handleOpenParameterPage = (process) => {
    setParameter(process);
    setParametersIsOpen(true);
  }

  const handleGoBack = () => {
    history.push(paths.performanceDefinition);
  }

  return (
    <>
      {
        parametersIsOpen ?
          <TypesOfSkillsSpecificIndicators
            parameter={parameter}
            handleGoBack={() => setParametersIsOpen(false)}
          />
          :
          <TypesOfSkillsSpecific
            handleGoBack={handleGoBack}
            handleOpenParameterPage={handleOpenParameterPage}
          />
      }
    </>
  );
}

export default TypesOfSkillsSwitch;