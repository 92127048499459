import * as actionTypes from "./actionTypes";
import {
  getTypesOfSkillsSpecificAPI,
  deleteTypeOfSkillsSpecificAPI,
  createTypeOfSkillsSpecificAPI,
  updateTypeOfSkillsSpecificAPI,
  duplicateTypeOfSkillsSpecificAPI,
  duplicateGroupTypeOfSkillsSpecificAPI,
  verifyTypeOfSkillsSpecificAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'No puede existir más de una competencia que comparta el mismo nombre y grupo';
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar la competencia porque está en uso';
      break;
    case "IN_USE_FORM":
      errorString = 'No es posible eliminar la competencia transversal porque está en uso en los formularios de desempeño';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getTypesOfSkillsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getTypesOfSkillsSpecificAPI();

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const deleteTypeOfSkillsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteTypeOfSkillsSpecificAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Competencia eliminada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createTypeOfSkillsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createTypeOfSkillsSpecificAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Competencia creada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateTypeOfSkillsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateTypeOfSkillsSpecificAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Competencia actualizada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const duplicateTypeOfSkillsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateTypeOfSkillsSpecificAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DUPLICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Competencia duplicada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const duplicateGroupTypeOfSkillsAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateGroupTypeOfSkillsSpecificAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DUPLICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Grupo duplicado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const verifyTypeOfSkillsSpecificAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.VERIFY });
  try {
    let response = await verifyTypeOfSkillsSpecificAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.VERIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.VERIFY_FULFILLED,
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.VERIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};