import React, { useState } from "react";
import TypesOfSkillsTransversal from "./typesOfSkillsTransversal";
import paths from "@icarius-localization/paths";
import { TypesOfSkillsTransversalIndicators } from "@icarius-pages/typesOfSkillsTransversalIndicators";

const TypesOfSkillsTransversalSwitch = ({ history }) => {

  const [parametersIsOpen, setParametersIsOpen] = useState(false);
  const [parameter, setParameter] = useState(null);

  const handleOpenParameterPage = (process) => {
    setParameter(process);
    setParametersIsOpen(true);
  }

  const handleGoBack = () => {
    history.push(paths.performanceDefinition);
  }

  return (
    <>
      {
        parametersIsOpen ?
          <TypesOfSkillsTransversalIndicators
            parameter={parameter}
            handleGoBack={() => setParametersIsOpen(false)}
          />
          :
          <TypesOfSkillsTransversal
            handleGoBack={handleGoBack}
            handleOpenParameterPage={handleOpenParameterPage}
          />
      }
    </>
  );
}

export default TypesOfSkillsTransversalSwitch;