import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import Loader from "@icarius-common/loader";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px" };

const DuplicateGroupDialog = (props) => {

  const {
    open,
    data,
    isLoading,
    handleConfirm,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'Todos los campos son obligatorios, y el grupo nuevo no puede estar en uso', severity: "error", duration: 10000 }));
  }

  const groupList = useMemo(() => {
    return [...new Set(data.filter((item) => item.group).map((item) => item.group))].map((item) => ({ key: item, value: item }));
  }, [data])

  const {
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, groupList, handleConfirm, openValidationError);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {'Duplicar por grupo de competencia'}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Loader open={isLoading} />
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-ref`}>{"Grupo a duplicar"}</InputLabel>
              <Select
                value={formData.ref}
                labelId={`label-ref`}
                id={`select-ref`}
                onChange={(e) => setFormValue(e.target.value, "ref")}
                margin={"none"}
              >
                {
                  groupList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nuevo grupo"}
              value={formData.group}
              onChange={(e) => setFormValue(e.target.value, "group")}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateGroupDialog;