
import React, { useState } from "react";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import { SketchPicker } from "react-color";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const ChangeColorDialog = (props) => {
  const {
    open,
    scoreColor,
    handleCloseDialog,
    index,
    modifyItem,
  } = props;

  const [color, setColor] = useState(scoreColor || "#d50000");
  const [error, setError] = useState(null);

  const handleChangeColor = (color) => {
    let colorStr = color.hex;
    if (color.rgb.a !== 1) {
      colorStr = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    }
    color.hex = colorStr;
    setColor(color);
  };

  const handleConfirm = () => {
    if (!color) {
      setError(getLocalizedErrorString("selectColor"));
      return;
    }

    modifyItem(index, color.hex ? color.hex : color, 'color');
    handleCloseDialog();
  };

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {getLocalizedString("selectColorTitle")}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleCloseDialog} />
        <DialogContent>
          <div>
            <SketchPicker
              color={color}
              onChangeComplete={handleChangeColor}
              width={window.innerWidth < 400 ? 200 : 400}
              disableAlpha={true}
            />
          </div>
          {
            error &&
            <Typography variant={"subtitle1"} className={"error-text"}>
              {error}
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction
            onClick={handleCloseDialog}
            text={getLocalizedString("disagree")}
          />
          <ButtonDialogAction
            onClick={handleConfirm}
            isAccept
            text={getLocalizedString("agree")}
          />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ChangeColorDialog;