import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
} from "@material-ui/core";
import { createTypeOfSkillsIndicatorAction, updateTypeOfSkillsIndicatorAction } from "../../actions";
import useHandleForm from "./useHandleForm";
import { useDispatch } from "react-redux";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {

  const {
    isLoading,
    data,
    parameter,
    classes,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleClose}
      type={"goBack"}
    />

  const create = (formData) => {
    dispatch(createTypeOfSkillsIndicatorAction({ ...formData, code: parameter.code }))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (formData) => {
    dispatch(updateTypeOfSkillsIndicatorAction({ ...formData, code: parameter.code }))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <CommonPage
      isLoading={isLoading}
      title={`${isCreate ? 'Crear' : 'Editar'} indicador para la competencia: ${parameter.name} - Grupo: ${parameter.group}`}
      menuItems={[goBackItem]}
      isNotGridPage
    >
      <Grid container item xs={12} direction="row" style={{ width: "95%", margin: "auto" }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nombre del indicador"}
              value={formData.indicatorName}
              onChange={(e) => setFormValue(e.target.value, "indicatorName")}
              inputProps={{ maxLength: 254 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
            <TextField
              required
              type='number'
              fullWidth
              margin={"none"}
              label={'Número del indicador'}
              value={formData.indicatorNumber}
              onChange={(e) => setFormValue(e.target.value, "indicatorNumber")}
              inputProps={{ min: "0", step: "1" }}
              helperText="Valor entre 1 y 9999"
              style={{ marginTop: 14 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}>
            <TextField
              fullWidth
              label="Descripción del comportamiento"
              variant="outlined"
              multiline
              rows={10}
              margin={"none"}
              value={formData.behaviorDescription}
              onChange={(e) => setFormValue(e.target.value, "behaviorDescription")}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              inputProps={{ spellCheck: 'false' }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ marginBottom: 35 }}>
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
    </CommonPage>
  )
}

export default withStyles(styles)(Form);