import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createTypeOfObjectivesAction, updateTypeOfObjectivesAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = ({ data, typeList, handleClose }) => {

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createTypeOfObjectivesAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateTypeOfObjectivesAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={'Código'}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Nombre'}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label`}>{"Tipo de objetivo"}</InputLabel>
            <Select
              value={formData.type}
              labelId={`label`}
              id={`select`}
              onChange={(e) => setFormValue(e.target.value, "type")}
              margin={"none"}
            >
              {
                typeList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
