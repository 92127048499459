import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET_TYPES_OF_SCORE = NAME + "/GET_TYPES_OF_SCORE";
export const GET_TYPES_OF_SCORE_REJECTED = NAME + "/GET_TYPES_OF_SCORE_REJECTED";
export const GET_TYPES_OF_SCORE_FULFILLED = NAME + "/GET_TYPES_OF_SCORE_FULFILLED";

export const DELETE_TYPES_OF_SCORE = NAME + "/DELETE_TYPES_OF_SCORE";
export const DELETE_TYPES_OF_SCORE_REJECTED = NAME + "/DELETE_TYPES_OF_SCORE_REJECTED";
export const DELETE_TYPES_OF_SCORE_FULFILLED = NAME + "/DELETE_TYPES_OF_SCORE_FULFILLED";

export const CREATE_TYPES_OF_SCORE = NAME + "/CREATE_TYPES_OF_SCORE";
export const CREATE_TYPES_OF_SCORE_REJECTED = NAME + "/CREATE_TYPES_OF_SCORE_REJECTED";
export const CREATE_TYPES_OF_SCORE_FULFILLED = NAME + "/CREATE_TYPES_OF_SCORE_FULFILLED";

export const MODIFY_TYPES_OF_SCORE = NAME + "/MODIFY_TYPES_OF_SCORE";
export const MODIFY_TYPES_OF_SCORE_REJECTED = NAME + "/MODIFY_TYPES_OF_SCORE_REJECTED";
export const MODIFY_TYPES_OF_SCORE_FULFILLED = NAME + "/MODIFY_TYPES_OF_SCORE_FULFILLED";