import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.typesOfSkillsTransversal],
        config: {
            headerName: "Código de competencia",
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.typesOfSkillsTransversal],
        config: {
            headerName: "Nombre de la competencia",
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.typesOfSkillsTransversal],
        config: {
            headerName: 'Fecha de actualización',
            field: "updateDate",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.typesOfSkillsTransversal],
        config: {
            headerName: 'Descripción de la competencia',
            field: "description",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.typesOfSkillsTransversal],
        config: {
            headerName: 'Indicadores',
            field: "indicators",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            cellClass: "currencyValueNoDecimal",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]