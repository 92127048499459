import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  data: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.SEARCH:
      return { ...state, isLoading: true };
    case actionTypes.SEARCH_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.SEARCH_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
