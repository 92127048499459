import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getData,
  getModeList,
  getConceptList,
  getFrequencyList,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  deleteTypesOfBenefitsAction,
  getTypesOfBenefitsAction,
} from "../actions";
import { getIsAdmin } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const daysAuthorizedList = [
  { key: 0, value: "0 días" },
  { key: 0.5, value: "1/2 día" },
  { key: 1, value: "1 día" },
  { key: 2, value: "2 días" },
  { key: 3, value: "3 días" },
  { key: 4, value: "4 días" },
  { key: 5, value: "5 días" },
  { key: 6, value: "6 días" },
  { key: 7, value: "7 días" },
  { key: 8, value: "8 días" },
  { key: 9, value: "9 días" },
  { key: 10, value: "10 días" },
];

const useTypesOfBenefits = () => {

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar o borrar
  const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);
  const modeList = useSelector(getModeList);
  const conceptList = useSelector(getConceptList);
  const frequencyList = useSelector(getFrequencyList);
  const isAdmin = useSelector(getIsAdmin);

  useEffect(() => {
    dispatch(getTypesOfBenefitsAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar el tipo de beneficio?",
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenCreateDialog = () => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleDelete = (documents) => {
    dispatch(deleteTypesOfBenefitsAction(documents));
  }

  const handleCloseCreateEditDuplicateDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setViewDialogIsOpen(false);
    setSelectedDocument(null);
  }


  const handleOpenViewDialog = (row) => {
    setSelectedDocument(row.data);
    setViewDialogIsOpen(true);
  }

  const handleCloseViewDialog = () => {
    setViewDialogIsOpen(false);
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
    viewDialogIsOpen,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDuplicateDialog,
    handleCloseViewDialog,
    handleOpenViewDialog
  }

  return {
    data,
    modeList,
    conceptList,
    frequencyList,
    daysAuthorizedList,
    isLoading,
    isAdmin,
    state,
    handlers
  }
}

export default useTypesOfBenefits;