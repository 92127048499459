import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionLogAction } from "../actions";
import { getData, getIsLoading } from "../selectors";
import { getIsExportingGrid } from "src/app/selectors";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import SearchInput from "./searchInput";
import ViewDialog from "./viewDialog";

const TransactionLog = () => {

  const [selectedData, setSelectedData] = useState(null);

  const dispatch = useDispatch();
  const exportingGridStatus = useSelector(getIsExportingGrid);

  const data = useSelector(getData);
  const isLoading = useSelector(getIsLoading);

  useEffect(() => {
    dispatch(getTransactionLogAction());
  }, [dispatch])

  const handleRowClick = (row) => {
    setSelectedData(row.data);
  }

  const searchItem = (ref) => <SearchInput gridRef={ref} />

  return (
    <CommonPage
      columnDefPage={paths.transactionLog}
      title={'Log de transacciones'}
      gridTitle={'Log de transacciones'}
      menuItems={[searchItem]}
      rowData={data}
      isLoading={isLoading || exportingGridStatus}
      handleRowClick={handleRowClick}
      hasHelp
      hasExpand
      hasSelectAll
    >
      {
        Boolean(selectedData) &&
        <ViewDialog
          open={Boolean(selectedData)}
          data={selectedData}
          handleClose={() => setSelectedData(null)}
        />
      }
    </CommonPage>
  );
}

export default TransactionLog;
