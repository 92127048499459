import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getIsExportingGrid } from "src/app/selectors";
import {
  getTypesOfSkillsAction,
  deleteTypeOfSkillsAction,
  duplicateGroupTypeOfSkillsAction,
  duplicateTypeOfSkillsAction,
  verifyTypeOfSkillsSpecificAction,
} from "../actions";
import { CLEAR_STATE } from "../actionTypes";
import { getData, getDateFormat, getIsLoading } from "../selectors";
import { getTypesOfSkillsIndicatorsAction } from "@icarius-pages/typesOfSkillsSpecificIndicators/actions";
import { CLEAR_STATE as CLEAR_STATE_INDICATORS } from "@icarius-pages/typesOfSkillsSpecificIndicators/actionTypes";
import { getIsLoading as getIsLoadingIndicators } from "@icarius-pages/typesOfSkillsSpecificIndicators/selectors";
import { formatNumberExactDecimals } from "@icarius-utils/format";

const useABM = () => {

  const dispatch = useDispatch();

  const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);
  const [duplicateGroupDialogIsOpen, setDuplicateGroupDialogIsOpen] = useState(false);
  const [duplicateSkillDialogIsOpen, setDuplicateSkillDialogIsOpen] = useState(false);
  const [createEditFormIsOpen, setCreateEditFormIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const showVerificationMessage = useCallback((verificationFailures) => {
    if (verificationFailures.length) {
      const number = formatNumberExactDecimals(verificationFailures[0].weighting, 2);

      dispatch(openSnackbarAction({
        msg: `La suma de los ponderados totaliza ${number}% para ${verificationFailures[0].value}. Es necesario complementar con otra competencia o reasignar los ponderados`,
        severity: 'error',
        duration: 10000,
      }));
      return;
    }

    dispatch(openSnackbarAction({
      msg: 'La suma de los ponderados es correcta y totaliza 100% para los grupos de las competencias',
      severity: 'info',
      duration: 10000,
    }));
    return;
  }, [dispatch])

  useEffect(() => {
    dispatch(getTypesOfSkillsAction())
      .then((resp) => {
        if (resp.status === 200 && Boolean(resp.data.data.length)) {
          showVerificationMessage(resp.data.verificationFailures);
        }
      })

    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch, showVerificationMessage])

  const data = useSelector(getData);
  const dateFormat = useSelector(getDateFormat);
  const isLoading = useSelector(getIsLoading);
  const isLoadingIndicators = useSelector(getIsLoadingIndicators);
  const isExportinGrid = useSelector(getIsExportingGrid);

  const handleVerify = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    const groups = selectedRows.map((item) => item.group).filter((value, index, array) => array.indexOf(value) === index);

    dispatch(verifyTypeOfSkillsSpecificAction({ groups: groups }))
      .then((resp) => {
        if (resp.status === 200) {
          showVerificationMessage(resp.data.verificationFailures);
        }
      })
  }

  const handleOpenCreateDialog = () => {
    setCreateEditFormIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un item", severity: "warning" }));
      return;
    }

    setCreateEditFormIsOpen(true);
    setSelectedItem(selectedRows[0]);
  };

  const handleCloseEditDialog = () => {
    setCreateEditFormIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un item", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: 'Atención',
      msg: '¿Desea borrar la competencia?',
      onConfirm: () => dispatch(deleteTypeOfSkillsAction({ code: selectedRows[0].code })),
    }));
  }

  const handleDuplicateSkill = (dataToSend) => {
    dispatch(duplicateTypeOfSkillsAction(dataToSend))
      .then(() => {
        handleCloseDuplicateSkillDialog();
      })
  }

  const handleOpenDuplicateSkillDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un item", severity: "warning" }));
      return;
    }

    setDuplicateSkillDialogIsOpen(true);
    setSelectedItem(selectedRows[0]);
  }

  const handleCloseDuplicateSkillDialog = () => {
    setDuplicateSkillDialogIsOpen(false);
    setSelectedItem(null);
  }

  const handleOpenDuplicateGroup = () => {
    setDuplicateGroupDialogIsOpen(true);
  }

  const handleCloseDuplicateGroupDialog = () => {
    setDuplicateGroupDialogIsOpen(false);
  }

  const handleDuplicateGroup = (dataToSend) => {
    dispatch(duplicateGroupTypeOfSkillsAction(dataToSend))
      .then(() => {
        handleCloseDuplicateGroupDialog();
      })
  }

  const handleOpenViewDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de files
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un item", severity: "warning" }));
      return;
    }

    dispatch(getTypesOfSkillsIndicatorsAction(selectedRows[0].code))
      .then((resp) => {
        if (resp.status === 200) {
          setSelectedItem(selectedRows[0]);
          setViewDialogIsOpen(true);
        }
      })
  }

  const handleCloseViewDialog = () => {
    setViewDialogIsOpen(false);
    setSelectedItem(null);
    dispatch({ type: CLEAR_STATE_INDICATORS });
  }

  const state = {
    createEditFormIsOpen,
    viewDialogIsOpen,
    duplicateSkillDialogIsOpen,
    duplicateGroupDialogIsOpen,
    selectedItem,
  };

  const handlers = {
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,

    handleOpenDuplicateSkillDialog,
    handleCloseDuplicateSkillDialog,
    handleDuplicateSkill,

    handleOpenDuplicateGroup,
    handleCloseDuplicateGroupDialog,
    handleDuplicateGroup,

    handleOpenViewDialog,
    handleCloseViewDialog,

    handleVerify,
    showVerificationMessage,
  };

  return {
    isLoading: isLoading || isExportinGrid || isLoadingIndicators,
    data,
    dateFormat,
    state,
    handlers,
  }
}

export default useABM;