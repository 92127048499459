import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import Form from "./form";
import { deleteTurnDefinitionAction } from "../../actions";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { openDialogAction } from "@icarius-common/dialog/actions";

const DataItem = (props) => {

  const { data, months, color, closeNew } = props;
  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      dispatch(deleteTurnDefinitionAction(data));
    }
  }

  const handleClosePublication = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: "Eliminar turno",
      msg: "¿Desea eliminar el turno?",
      onConfirm: handleDeletePublication,
    }));
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              isCreate ?
                <DataContainer lg={12} sm={12} md={12} text={"Creación de turno"} />
                :
                <>
                  <DataContainer sm={12} md={3} lg={3} text={data.code} />
                  <DataContainer sm={12} md={9} lg={9} text={data.name} />
                </>
            }
          </Grid>

          <Grid container direction="row" item xs={1} justify="center">
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          data={data}
          months={months}
          handleClose={isCreate ? closeNew : handleClosePublication}
        />
      }
    </>
  );
}

export default DataItem;
