import { useState } from "react";
import * as yup from 'yup';

const DETAILS_ERRORS = {
  "INCOMPLETE_FIELDS": "Verifique que todos los campos esten completos",
  "LESS_THAN_ONE": "El valor mínimo de la nota es 0,1",
  "VALUES_DO_NOT_MATCH": "Verifique que coincidan los umbrales minimos y máximos de las notas",
  "MAX_SCORE": "La nota máxima no coincide con la especificada para el tipo de nota",
  "NAME_LENGTH_GREATER_THAN": "El nombre de la nota no puede tener más de 60 caracteres",
  "SCORE_LENGTH_GREATER_THAN": "Las notas mínimas y máximas no pueden ser mayores a 9999"
}
const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, invalidNameCallback, isDuplicate, typeOfScoreNames, typeOfScoreCodes, invalidCodeCallback, invalidDetailsNamesCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {
    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'details') {
        return isCreate ? [] : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "code",
      "name",
      "maxScore",
      "details",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());
  const {
    code,
    name,
    maxScore,
    details,
  } = formData;
  const detailsNameIsValid = () => {
    const counts = {};
    details.map(el => el.name).forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    if (Object.keys(counts).length !== details.length) {
      return false;
    }

    return true;
  }
  const detailsAreValid = () => {
    if (details.length === 0) return false;
    const minimumValues = details.map(el => el.minimum);
    const maximumValues = details.map(el => el.maximum);

    if (minimumValues.length !== maximumValues.length) {
      return DETAILS_ERRORS.INCOMPLETE_FIELDS;
    }

    // Primero reviso si tengo un número par de repetidos
    for (let i = 0; i < details.length; i++) {
      const { name, minimum, maximum, color } = details[i];

      if (name.length > 60) {
        return DETAILS_ERRORS.NAME_LENGTH_GREATER_THAN;
      }
      // Verifico tener los campos necesarios
      if (!color || parseFloat(minimum) >= parseFloat(maximum) || !name) {
        return DETAILS_ERRORS.INCOMPLETE_FIELDS;
      }

      if (i === 0) {
        if (parseFloat(minimum) !== 0.1) {
          return DETAILS_ERRORS.LESS_THAN_ONE;
        }
      }

      if (i !== (details.length - 1)) {
        if ((parseFloat(maximum) + 0.1) !== parseFloat(details[i + 1].minimum)) {
          return DETAILS_ERRORS.VALUES_DO_NOT_MATCH;
        }
      } else {
        // Estoy en el último elemento
        // Verifico que coincida la nota máxima
        if (parseFloat(maximum) !== parseFloat(maxScore)) {
          return DETAILS_ERRORS.MAX_SCORE;
        }

      }
    }

    return true;
  }

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'code': yup.string().required().max(8),
      'name': yup.string().required().max(60),
      'maxScore': yup.string().required().max(6),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const nameIsValid = () => {
    if (!isCreate && !isDuplicate) return true;
    return !(typeOfScoreNames.includes(name));
  }

  const codeIsValid = () => {
    if (!isCreate && !isDuplicate) return true;
    return !(typeOfScoreCodes.includes(code));
  }

  const submit = async () => {
    if (await dataIsValid()) {
      if (detailsAreValid() === true) {
        if (codeIsValid()) {
          if (nameIsValid()) {
            if (detailsNameIsValid()) {
              const dataToSend = {
                code,
                name,
                maxScore,
              }

              if (!isCreate) {
                dataToSend.code = data.code;
              }

              dataToSend.details = details.map((item) => {
                const newFormattedDetail = {
                  name: item.name,
                  minimum: parseFloat(item.minimum),
                  maximum: parseFloat(item.maximum),
                  color: item.color,
                };

                return newFormattedDetail;
              });

              isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
              return true;
            } else {
              invalidDetailsNamesCallback();
              return false;
            }
          } else {
            invalidNameCallback();
            return false;
          }
        } else {
          invalidCodeCallback();
          return false;
        }
      } else {
        invalidDataCallback(detailsAreValid());
        return false;
      }
    } else {
      if (maxScore.length > 5) {
        invalidDataCallback("La notá maxima no puede ser mayor a 9999");
      } else if (code.length > 8) {
        invalidDataCallback("El código no puede exceder los 8 caracteres");
      } else if (name.length > 60) {
        invalidDataCallback("El nombre no puede exceder los 60 caracteres");
      }
      return false;
    }
  }

  const createItem = () => {
    //agregar el objeto default a details
    const newEmptyDetail = {
      name: "",
      minimum: "",
      maximum: "",
      color: "",
    }

    setFormValue([...details, newEmptyDetail], 'details');
  }

  const deleteItem = (index) => {
    const newDetails = [...details];
    newDetails.splice(index, 1);
    setFormValue(newDetails, 'details');
  }

  const modifyItem = (index, value, fieldName) => {
    const updatedDetail = { ...details[index] };
    updatedDetail[fieldName] = value;

    const newDetails = [...details];
    newDetails.splice(index, 1, updatedDetail);
    setFormValue(newDetails, 'details');
  }


  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    isCreate,
    formData,
    setFormValue,
    createItem,
    deleteItem,
    modifyItem,
    submit,
  };
}

export default useHandleForm;
