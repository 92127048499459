import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { withStyles } from "@material-ui/core/styles";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});


const ViewDialog = (props) => {

  const { open, data, handleClose, classes } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Log de transacción"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                fullWidth
                margin={"none"}
                label={'Código de usuario'}
                value={data['Código de usuario']}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                fullWidth
                margin={"none"}
                label={'Nombre del usuario'}
                value={data['Nombre del usuario']}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                fullWidth
                margin={"none"}
                label={'Fecha de ejecución'}
                value={data['Fecha de ejecución']}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                fullWidth
                margin={"none"}
                label={'Hora de ejecución'}
                value={data['Hora de ejecución']}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* sql */}
              <TextField
                fullWidth
                label="Sentencias de SQL"
                variant="outlined"
                multiline
                rows={15}
                margin={"none"}
                value={data['Sentencias de SQL']}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                inputProps={{ spellCheck: 'false' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(ViewDialog);
